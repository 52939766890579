import React, { useState } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import { Formik, Form, Field } from 'formik'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import {listLink, formClass, inputClass, searchIcon, button} from '../components/layout.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'



const SearchPage = () => {
  const data = useStaticQuery(graphql`
query {
    localSearchPages {
        index
        store
      }
}
`)
const index = data.localSearchPages.index
const store = data.localSearchPages.store
const [query, setQuery] = useState(null)
  const results = useFlexSearch(query, index, store)

  return (
    <Layout pageTitle="Search">
      <Formik
        initialValues={{ query: '' }}
        onSubmit={(values, { setSubmitting }) => {
          setQuery(values.query)
          setSubmitting(false)
        }}
      >
        <Form className={formClass}>
          <Field name="query" className={inputClass}/>
          <button type="submit" className={button}> <FontAwesomeIcon className={searchIcon} icon={faSearch} /></button>
        </Form>

        
      </Formik>
      <h3>Results</h3>
      <div>
        {results.map(result => (
          <p key={result.id} className={listLink}><Link to={"/" + result.slug}>{result.title}</Link></p>
        ))}
      </div>
    </Layout>
  )
}

export default SearchPage